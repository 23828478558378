<template>
    <div>
      <div style="text-align: center">
        <h3>Tambah Device</h3>
      </div>
      <br />
      <form v-on:submit.prevent="addDevice">
        <label>Nama Device</label>
        <input type="text" class="form-control" v-model="form.name" autofocus required />
        <br />
        <label>Tipe Whatsapp</label>
        <select v-model="form.multidevice" class="form-control">
            <option value="1">Multidevice</option>
        </select>
        <br />
        <div v-if="form.multidevice == '1'">
            Catatan Untuk Multidevice
            <br>
            <b> 1. Multidevice whatsapp masih berstatus beta, mungkin berberapa fitur masih kurang stabil </b>
            <br>
            <b> 2. Resiko banned saat kirim pesan blast terutama untuk nomor yang belum dikenal lebih tinggi </b>
            <br>
        </div>
        <label>Tipe Server</label>
        <select v-model="form.type" class="form-control">
            <option value="unofficial" selected>Unofficial API</option>
          <!--  <option value="official" disabled>Official API (Akan Datang)</option>-->
        </select>
        <div class="button-group d-flex pt-25">
            <button type="submit" class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
              Tambah
            </button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          name: "",
          multidevice: "1",
          type: "unofficial"
        },
      };
    },
    mounted() {
  
    },
    methods: {
      async addDevice(){
        try{
          let request = this.form
          request.multidevice = parseInt(request.multidevice)
          let response = await this.$store.dispatch('device_create', request)
          
          this.$toast.success(response.data.message);
          this.$root.$emit("toggle_modal", "new_device")
          this.$root.$emit("refresh_table", true)
        }catch(err){
          this.$toast.error(err.response.data.message);
        }
      },
    },
  };
  </script>
  
