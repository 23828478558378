<template>
    <div>
        <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
            :isLoading.sync="table.isLoading" :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }" :columns="table.columns" :rows="table.rows">
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'name'">
                    {{ props.row.name }}
                    <span v-if="props.row.multidevice == 1" class="badge badge-success">Multidevice</span>

                </span>
                <span v-else-if="props.column.field == 'action'">
                    <span v-if="props.row.status == 'not connected'">
                        <ul class="mb-0 d-flex flex-wrap float-right">
                            <li>
                                <router-link :to="'/device/scan?id=' + props.row.id">
                                    <a class="btn btn-info px15 text-white" style="margin: 5px"> Scan QR </a>
                                </router-link>
                            </li>
                            <li>
                                <router-link :to="'/device/edit?id=' + props.row.id">
                                    <a class="btn btn-warning px15" style="margin: 5px"> Detail </a>
                                </router-link>
                            </li>
                            <li>
                                <a v-on:click="deleteDevice(props.row.id)" class="btn btn-danger px15 text-white"
                                    style="margin: 5px"> Hapus </a>
                            </li>
                        </ul>
                    </span>
                    <span v-if="props.row.status == 'connected'">
                        <ul class="mb-0 d-flex flex-wrap float-right">
                            <li>
                                <a v-on:click="relogDevice(props.row.id)" class="btn btn-secondary px15 text-white"
                                    style="margin: 5px"> Relog </a>
                            </li>
                            <li>
                                <a v-on:click="logoutDevice(props.row.id)" class="btn btn-info px15 text-white" style="margin: 5px"> Logout </a>
                            </li>
                            <li>
                                <router-link :to="'/device/edit?id=' + props.row.id">
                                    <a class="btn btn-warning px15" style="margin: 5px"> Detail </a>
                                </router-link>
                            </li>
                            <li>
                                <a v-on:click="deleteDevice(props.row.id)" class="btn btn-danger px15 text-white"
                                    style="margin: 5px"> Hapus </a>
                            </li>
                        </ul>
                    </span>
                </span>
                <span v-else-if="props.column.field == 'device_key'">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" :value="props.row.device_key"
                            :ref="'device_key'+props.row.id" v-on:focus="$event.target.select()" readonly>
                        <div class="input-group-append">
                            <button class="clipboard btn btn-outline-secondary" type="button"
                                v-on:click="copy(props.row.id)">Copy</button>
                        </div>
                    </div>
                </span>
                <span v-else-if="props.column.field == 'status'">
                    <span v-if="props.row.status == 'connected'" class="badge badge-success">Terkoneksi</span>
                    <span v-else-if="props.row.status == 'not connected'" class="badge badge-danger">Tidak
                        Terkoneksi</span>
                    <span v-else-if="props.row.qr == 'dc' || props.row.qr == 'koneksi'"
                        class="badge badge-danger">Koneksi Terputus</span>
                </span>
                <span v-else-if="props.column.field == 'webhook_media_quota' && props.row.webhook_media_quota>0">
					<p>Quota : {{formatQuota(props.row.webhook_media_quota/1000000) }} GB</p>
					<p>Expired : {{ buatTglExpired(props.row.created_media_quota*1000) }} </p>
					<br />
                    <button @click="kosongkanStorage(props.row.device_id)" class="btn btn-danger">Kosongkan Storage</button>
                </span>
                <span v-else>
                    {{props.formattedRow[props.column.field]==0 ? '':props.formattedRow[props.column.field]  }}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                table: {
                    total_records: 100,
                    isLoading: true,
                    columns: [{
                            label: "Nama Device",
                            field: "name",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "API Key",
                            field: "device_key",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "30%",
                        },
                        {
                            label: "Server ID",
                            field: "server_id",
                            sortable: false,
                            width: "10%",
                        },
                        {
                            label: "Status",
                            field: "status",
                            filterOptions: {
                                enabled: true,
                            },
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Webhook Media",
                            field: "webhook_media_quota",
                            sortable: false,
                            width: "20%",
                        },
                        {
                            label: "Action",
                            field: "action",
                            sortable: false,
                            width: "20%",
                        },
                    ],
                    rows: [],
                    serverParams: {
                        columnFilters: {},
                        sort: [{
                            field: "",
                            type: "",
                        }, ],
                        page: 1,
                        limit: 10,
                    },
                },
            };
        },
        mounted() {
            this.fn = () => {
                this.loadItems();
            }
            this.$root.$on("refresh_table", this.fn);
            this.loadItems();
        },
        beforeDestroy() {
            this.$root.$off("refresh_table", this.fn);
        },
        methods: {
            async kosongkanStorage(device_id){
				
				try {
                    let res = await this.$store.dispatch("device_kosongkan_storage_s3", {
                        id: device_id
                    })
                    this.$toast.success(res.data.message)
                    this.loadItems();
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
			},
            formatQuota(angka){
				return angka.toString().replace(".", ",")
			},
            buatTglExpired(tgl){
				let d = new Date(tgl)
				return d.getDate()  + "-" + (d.getMonth()+2) + "-" + d.getFullYear() + " " +
d.getHours() + ":" + d.getMinutes();

			},
            copy(id) {
                this.$toast.success("Copy API Key Berhasil");
                this.$refs['device_key' + id].focus();
                document.execCommand('copy');
            },
            updateParams(newProps) {
                this.table.serverParams = Object.assign({},
                    this.table.serverParams,
                    newProps
                );
            },
            onPageChange(params) {
                this.updateParams({
                    page: params.currentPage
                });
                this.loadItems();
            },
            onPerPageChange(params) {
                this.updateParams({
                    limit: params.currentPerPage
                });
                this.loadItems();
            },
            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params.sortType,
                        field: this.table.columns[params.columnIndex].field,
                    }, ],
                });
                this.loadItems();
            },
            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },
            async loadItems() {
                this.table.isLoading = true
                try {
                    let response = await this.getFromServer(this.table.serverParams);
                    this.table.total_records = response.data.data.total_records;
                    this.table.rows = response.data.data.devices;
                    
                } catch (err) {
                    this.$toast.error("Too many request");
                }
                this.table.isLoading = false
            },
            async getFromServer(param) {
                return await this.$store.dispatch("device_list", param);
            },
            async logoutDevice(id) {
                if (!confirm("Yakin ingin logout device ?")) return

                try {
                    let res = await this.$store.dispatch("device_logout", {
                        id: id
                    })
                    this.$toast.success(res.data.message)
                    this.loadItems();
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
            async deleteDevice(id) {
                if (!confirm("Yakin ingin menghapus device ?")) return

                try {
                    let res = await this.$store.dispatch("device_delete", {
                        id: id
                    })
                    this.$toast.success(res.data.message)
                    this.loadItems();
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
            async relogDevice(id) {
                if (!confirm("Yakin ingin relog device ?")) return

                try {
                    let response = await this.$store.dispatch('device_relog', {
                        id: parseInt(id)
                    })
                    this.$toast.success(response.data.message);
                } catch (err) {
                    this.$toast.error(err.response.data.message);
                }
            },
        },
    };
</script>

<style scoped>
    .margin5 {
        margin: 5px;
    }
</style>
